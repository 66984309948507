import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const common = css`
  width: 100%;
  font-size: 1em;
`;

const Field = styled.div`
  ${common}
`;
const Input = styled.input`
  ${common}
`;
const TextArea = styled.textarea`
  ${common}
`;

export const ContactForm: FC = () => {
  return (
    <form
      action="https://formspree.io/contact@travisgeis.com"
      method="POST"
      className="ui form"
    >
      <Field className="field">
        <label htmlFor="name">Name</label>
        <Input
          type="text"
          className="form-control"
          name="name"
          placeholder="Your name"
        />
      </Field>
      <Field className="field">
        <label htmlFor="_replyTo">Email (if you want a reply)</label>
        <Input
          type="email"
          className="form-control"
          name="_replyTo"
          placeholder="you@example.com"
        />
      </Field>
      <Field className="field">
        <label htmlFor="body">Message</label>
        <TextArea
          className="form-control"
          name="body"
          rows={10}
          placeholder="Hey, Travis..."
        />
      </Field>
      <input type="submit" className="ui primary button" value="Send" />
    </form>
  );
};
