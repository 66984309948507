import * as React from 'react';
import { Helmet } from 'react-helmet';

import './travis-print-one.css';

export const TravisPrintOne = () => (
  <Helmet>
    <link rel="stylesheet" href="./travis-print-one.css" />
  </Helmet>
);
