// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-page-slug-tsx": () => import("./../../../src/pages/{page.slug}.tsx" /* webpackChunkName: "component---src-pages-page-slug-tsx" */),
  "component---src-pages-post-slug-tsx": () => import("./../../../src/pages/{post.slug}.tsx" /* webpackChunkName: "component---src-pages-post-slug-tsx" */),
  "component---src-pages-project-slug-tsx": () => import("./../../../src/pages/{project.slug}.tsx" /* webpackChunkName: "component---src-pages-project-slug-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */)
}

